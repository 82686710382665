<template>
  <div class="page-margin">
    <PlatformItem
      v-for="item in platformData"
      :key="item.introduction"
      :introduction="item.introduction"
      :description="item.description"
      :keyPoints="item.keyPoints"
      :imageFileName="item.imageFileName"
      :flipped="item.flipped"
    />
  </div>
</template>

<script>
import LeadGenData from "@/data/leadGen.json";
import PlatformItem from "@/components/CreditAnalytics/LeadGen/PlatformItem.vue";

export default {
  name: 'Platform',
  components: {
    PlatformItem,
  },
  data() {
    return {
      platformData: LeadGenData?.platform,
    };
  },
};
</script>

<style lang="scss" scoped>
.page-margin {
  margin-top: calc(#{$mds-space-8-x} + #{$mds-space-three-quarter-x});
  margin-bottom: calc(#{$mds-space-8-x} + #{$mds-space-three-quarter-x});
}
</style>