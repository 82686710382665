<template>
  <mds-layout-grid>
    <mds-row
      align-horizontal="center"
      align-vertical="center"
      class="dv-solution-item-row"
    >
      <mds-col class="dv-solution-item-col" :cols="12">
        <div class="dv-solution-item-row-icon">
          <img class="dv-solution-item-row-icon-img" :src="imagePath()" :alt="imageFileName" />
        </div>
      </mds-col>
    </mds-row>
    <mds-row
      align-horizontal="center"
      align-vertical="center"
      class="dv-solution-item-row"
    >
      <mds-col class="dv-solution-item-col" :cols="12">
        <div v-html="description" class="dv-solution-item-row-desc"/>
      </mds-col>
    </mds-row>
  </mds-layout-grid>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';

export default {
  name: 'SolutionItem',
  components: {
    MdsRow,
    MdsCol,
    MdsLayoutGrid,
  },
  methods: {
    imagePath() {
      return require('../../../assets/' + this.imageFileName);
    },
  },
  props: {
    description: {
      type: String,
      default: '',
      required: true,
    },
    imageFileName: {
      type: String,
      default: '',
      required: false,
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dv-solution-item {
  &-row {
    &-desc {
      @include mds-level-5-heading();
      // width: 650px;
      height: 76px;
    }
    &-icon {
      background: $mds-background-color-white;
      width: 156px;
      height: 156px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: $mds-space-2-and-a-half-x;
      &-img {
        padding: $mds-space-1-x;
        width: 70%;
        height: 70%;
      }
    }
  }
  &-col {
    text-align: center;
    justify-content: center;
    display: flex;
  }
}
</style>
