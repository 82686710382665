<template>
  <div class="mcia-outer-conatiner">
    <div class="mcia-inner-container">
      <div>
        <div class="product-key-features-title">{{ title }}</div>
        <mds-layout-grid class="product-key-features">
          <mds-row align-horizontal="center" align-vertical="center">
            <mds-col
              v-for="(item, index) in featuresList"
              align-vertical="center"
              :key="`key-feature-${index}`"
              :cols="12"
              :cols-at-m="4"
            >
              <SolutionItem
                :key="`key-feature-item-${index}`"
                :description="item"
                :imageFileName="`lead-gen-${index}.svg`"
              />
            </mds-col>
          </mds-row>
        </mds-layout-grid>
      </div>
    </div>
  </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import SolutionItem from '@/components/CreditAnalytics/LeadGen//SolutionItem.vue';
export default {
  name: 'Solution',
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    SolutionItem,
  },
  data() {
    return {
      title: 'Our Solution',
    };
  },
  props: {
    featuresList: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.product-key-features {
  height: 100%;
  margin: 0 auto;
  padding-bottom: calc(#{$mds-space-6-x} + #{$mds-space-quarter-x});
  &-title {
    text-align: center;
    @include mds-level-2-heading($bold: true);
    color: $mds-text-color-primary;
    padding-top: calc(#{$mds-space-6-x} + #{$mds-space-quarter-x});
    padding-bottom: 35px;
  }
}
.mcia-outer-conatiner {
  background-color: #f9f9f9;
  .mcia-inner-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
